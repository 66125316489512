<template>
  <div class="auth-window">
    <div class="auth-window__form-section">
      <header class="auth-window__form-header">
        <h3 class="auth-window__form-title" @click="handleAnimate">Login</h3>
      </header>

      <div class="auth-window__form auth-window__form_login">
        <!-- @submit.prevent="submitHandler" -->
        <div
          class="auth-window__form-input"
          :class="{
            'input_invalid' : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email), 
            'input_valid' : !$v.email.$invalid 
          }"
        >
          <input type="text" v-model="email" placeholder="Email" />

          <small
            class="helper-text invalid"
            v-if="$v.email.$dirty && !$v.email.required"
          >This field must not be empty</small>
          <small
            class="helper-text invalid"
            v-else-if="$v.email.$dirty && !$v.email.email"
          >Invalid Email</small>
        </div>

        <div
          class="auth-window__form-input"
          :class="{
            'input_invalid' : ($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength), 
            'input_valid' : !$v.password.$invalid
          }"
        >
          <input type="password" v-model="$v.password.$model" placeholder="Password" />

          <small
            class="helper-text invalid"
            v-if="$v.password.$dirty && !$v.password.required"
          >This field must not be empty</small>
          <small
            class="helper-text invalid"
            v-else-if="$v.password.$dirty && !$v.password.minLength"
          >The field must be at least {{ $v.password.$params.minLength.min }} characters. {{ password.length }}</small>
        </div>

        <div class="auth-window__action-row auth-window__action-row_login">
          <vue-recaptcha
            class="captha captha_login"
            ref="recaptcha"
            :sitekey="sitekey"
            @verify="onVerify"
            @expired="onExpired"
          />

          <button class="action-auth-btn action-auth-btn_login" @click="submitHandler">Login</button>

          <!-- OLD Cabinet -->

          <a
            href="https://dash.smsplaza.io/dashboard/auth/login"
            class="action-auth-btn action-auth-btn_login action-auth-btn_login-old"
          >OLD Cabinet</a>

          <span class="auth-window__change-action auth-window__change-action_forgot">
            Forgot password?
            <router-link class="simple-link simple-link_underline" to="/reset">Reset</router-link>
          </span>

          <span class="auth-window__change-action auth-window__change-action_reg-link">
            Don't you have an account?
            <router-link class="simple-link simple-link_underline" to="/register">Registration</router-link>
          </span>

          <!-- <transition name="fade" v-if="error">
            <p class="app__error">{{error}}</p>
          </transition>-->

          <AppError :error="error" />
        </div>
      </div>
    </div>

    <!-- <div class="auth-window__slider">Image for login here</div> -->
  </div>
</template>

<script>
import { MamaherService } from "@/services/manager.service";

import { email, required, minLength } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { ToastProgrammatic as Toast } from "buefy";

import AppError from "@/components/app/root/AppError";

export default {
  components: { VueRecaptcha, AppError },
  data: () => ({
    error: null,
    email: "",
    password: "",
    recaptcha: "",
    sitekey: "6LeAyLYUAAAAANWXmSpKGVpGRb1TNOW09mUmfpLR"
  }),
  validations: {
    email: { email, required },
    password: { required, minLength: minLength(1) }
  },
  computed: {
    ...mapState("auth", ["authenticationError"])
  },

  // mounted() {
  //   // let referral = this.$route.query.referral;

  //   if (this.$route.query && this.$route.query.referer) {
  //     this.$cookies.set("referer", this.$route.query.referer);
  //     localStorage.setItem("REFERRAL_KEY", referral);

  //     MamaherService.visitReferral(referral);
  //     // this.$cookies.set("referral", this.$route.query.referral);
  //   }
  // },

  mounted() {
    let referral = this.$route.query.referral;

    console.log("referral", referral);

    if (this.$route.query.referer) {
      this.$cookies.set("referer", this.$route.query.referer);
    } else if (referral) {
      localStorage.setItem("REFERRAL_KEY", referral);

      MamaherService.visitReferral(referral);
      // this.$cookies.set("referral", this.$route.query.referral);
    }
  },

  methods: {
    ...mapMutations("auth", ["clearAuthError"]),

    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      const user = {
        email: this.email,
        password: this.password,
        recaptcha: this.recaptcha
      };

      await this.$store.dispatch("auth/login", {
        user,
        cb: () => {
          this.error = null;
        },
        cbError: err => {
          console.log(err);

          if (err) {
            if (err.detail) {
              console.log(err);
              this.error = err.detail;
            }
            // else if (
            //   err.detail == "No active account found with the given credentials"
            // ) {
            //   this.error = "Your account is not verified.";
            // }
            else if (err.recaptcha) {
              this.error = "Confirm that you are not a robot";
            } else if (err.detail) {
              this.error = err.detail;
            }
          }

          this.clearForm();
          this.handleAnimate();
        }
      });

      // if (this.authenticationError) {
      // this.errorHandling();
      // this.clearAuthError();
      // this.clearForm();
      // }
    },
    errorHandling() {
      this.$toasted.show(
        `Your email or password is incorrect. ${this.authenticationError}.`,
        {
          theme: "toasted-primary",
          position: "top-right",
          duration: 10000,
          className: "danger-notification"
        }
      );
    },
    clearForm() {
      this.resetRecaptcha();
    },
    onSubmit: function() {
      this.$refs.invisibleRecaptcha.execute();
    },
    onVerify: function(response) {
      this.setTokenCaptcha(response);
    },
    onExpired: function() {
      this.setTokenCaptcha("");
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.setTokenCaptcha("");
      this.$refs.recaptcha.reset(); // Direct call reset method
    },

    setTokenCaptcha(recaptchaToken) {
      this.recaptcha = recaptchaToken;
    },

    handleAnimate() {
      let elem = document.querySelector(".auth-window");
      if (!elem.classList.contains("errorAminate")) {
        elem.classList.add("errorAminate");
      }
      setTimeout(() => {
        elem.classList.remove("errorAminate");
      }, 1300);
    }
  }
};
</script>


