import { ApiService } from "./api.service";
import { BASE_URL } from "@/utils/constants";

const MamaherService = {
  visitReferral: async function(code) {
    const requestData = {
      method: "post",
      url: `${BASE_URL}api/users/visit_referral/`,
      data: {
        referral: code
      }
    };

    try {
      const response = await ApiService.customRequest(requestData);

      console.log("response", response);
    } catch (error) {
      console.log(error);
    }
  }
};

export default MamaherService;

export { MamaherService };
